.div-red {
  background-color: red;
}

.btn-entregas-acopios{
  padding-top: 10px;
  margin-top: 20px;
  padding-left: 25px;
  font-size: 18px;
}

.titulo-input {
  font-size: 18px;
  font-weight:500;
  margin-bottom: 10px;
  margin-bottom: 5px;
}

.info-input{
  font-size: 14px;
}

.input-placeholder::placeholder {
  font-size: 10px;
}

.importe-total {
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
}

.info-entregas-titulo {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.5px;
}

.info-entregas-link {
  color: var(--primary-500, #789D16);
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-top: 5px;
}

.info-entregas-variable {
  color: var(--Neutral-700, #464741);
  /* Body 2/Lato Regular */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.resumen-entrega-titulo{
  color: #000;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 5px;
}

.resumen-entrega-subtitulo{
  color: #000;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
  padding-top: 5px;
}

.resumen-entrega-elemento{
  color: var(--Neutral-700, #464741);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
  padding-top: 5px;
}

.resumen-entrega-valor{
  color: #000;
  /* Subtitle 1/Lato Regular */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
  padding-top: 5px;
}

.resumen-entrega-firma {
  width: 200px;
  height: 125px;
  flex-shrink: 0;
  background: #FFF;
  border-bottom: #000 solid 1px;
}

.resumen-entrega-firmante {
  color: var(--Neutral-700, #464741);
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}


.signatureCanvas {
  border: 1px solid black;
  width: 250px;
  min-height: 125px;
  background-color: white;
}


.form-control-height {
  min-height: 50px;
  border-color: '#FFFFFF';
  border-style: none;
}

.is-invalid {
  border-color: '#FFFFFF';
  border-style: none;
}



.seleccion-titulo {
  color: #000;
  /* H6/Lato Medium */
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}

.seleccion-subtitulo {
color: var(--Neutral-500, #777771);
/* Body 1/Lato Regular */
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 155.556% */
letter-spacing: 0.5px;

}

.volver-button {
  font-size: 20px !important;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.volver-button:hover {
  text-decoration: underline;
}