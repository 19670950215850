.label-risk {
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  /* width: fit-content; */
  width: 100%;
}

.label-risk h6 {
  margin-bottom: 0;
}
