@import "https://fonts.googleapis.com/css?family=Lato:400,500,600";
@import "https://fonts.googleapis.com/css?family=Forum:400";
@import "https://fonts.googleapis.com/css?family=Open+Sans:400";

/* BODY */
body {
  background-color: rgba(242, 241, 232, 0.7);
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  /* font-size: 18px;
  line-height: 28px; */
}

/* FONTS */ 

/* Forum */
.font-forum-normal-400 {
  font-family: 'Forum';
  font-style: normal;
  font-weight: 400;
  color: #4F4539;
}

/* Lato */
.font-lato-normal-400 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}

.font-lato-normal-500 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
}

.font-lato-normal-600 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
}

/* Open Sans */
.font-open-normal-400 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}

.body-0 {
  font-size: 1rem;
  line-height: 1.500rem;
}

.body-1 {
  font-size: 1.125rem;
  line-height: 1.556rem;
}

.subtitle-1 {
  color: #5B5B5B;
}

.link-btn {
  font-size: 0.875rem;
}

/* INPUT */

input {
  color: #464741 !important;
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  /* font-size: 18px !important; */
  /* line-height: 28px !important; */
}

/* BUTTON */

button {
  color: #131F00 !important;
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.btn {
  border-radius: 100px; 
}

.btn-sirio {
  background-color: #FFB94C;
}

.btn-gray, .btn-primary, .btn-danger {
  color: #ffffff !important;
}

.btn-reset {
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  bottom: 8%;
  padding-right: 5%;
  outline: none;
}

/* SIDERBAR */

.sidebar {
  background-color: #374E00 !important;
  display: grid;
  /* font-size: 16px !important;
  line-height: 28px !important; */
}

.sidebar .nav {
  padding: 14px;
}
.sidebar .nav > li.active > a {
  color: #FAFFDF;
  background: rgba(0, 0, 0, 0.16);
  border-radius: 12px;
}
.sidebar .nav > li.active > a i {
  color: #91B833;
}
.sidebar .sub-menu > li > a:after {
  content: inherit;
}
.sidebar .sub-menu > li:after {
  content: inherit;
}
.sidebar .sub-menu > li:before {
  content: inherit;
}

.acerca-de {
  padding: 7px 20px 20px 20px;
  margin-left: 0.8rem;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.6);
  display: block;
  text-decoration: none;
  margin-top: auto;
  align-self: end;
}

.sidebar-minify-btn {
  margin-top: -1rem !important;
}

/* DROPDOWN MOBILE */

@media (max-width: 768px) {
  .dropdown-open {
    display: flex;
    flex-direction: column;
  }
  .collapse .nav > li.active > a {
    color: #FAFFDF;
    border-radius: 12px;
  } && i {
    color: #91B833;
  }

  .dropdown-open .collapse.show ul {
    display: flex;
    flex-direction: column;
    margin-left: 5px !important;
  } && ul {
    display: flex;
    flex-direction: column;
    /* background-color:  	#F5F5F5; */
    justify-content: flex-start !important;
    padding-bottom: 5px;
    padding-top: 5px;
  } && li:not(.dropdown) {
    width: 200%;
    color:#374E00;
    border-bottom: solid thin #F5F5F5 !important;
    margin-bottom: 5px !important;
  } && a span {
    color:black;
    font-size: large;
    text-transform: uppercase;
    margin-bottom:1px;
  } :checked {
    background-color: none;
  }
  .dropdown-open .collapse ul ul li a i {
    color: #91B833;
  }
  .caret {
    color: #374E00 !important;
  }
  .navbar-header:has(.collapse.show) {
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 10px; 
  }
}
.navbar-toggler:has(+ .show) {
  margin-top: -30px;
}

.navbar-user:has(+ .navbar-toggler + .collapse.show) {
  display:none !important;
}

.sidebar li.closed > a .caret:before {
  right: 1.5rem !important;
  position:relative !important;
}

.sidebar li.active > a .caret:before {
  right: 1.5rem !important;
  position:relative !important;
}



/* PAGE-HEADER */
.page-header-left {
  padding-top: 8px;
}

/* LABEL-CATEGORY */

.label-category {
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  width: fit-content;
}

.label-category h6 {
  margin-bottom: 0;
}

/* CARD-BASIC */ 

.card-container {
  background-color: white;
  border-radius: 20px;
  padding: 30px;
}

/* CARD-BASIC */ 

.card-basic {
  background-color: white;
  border-radius: 20px;
  padding: 30px;
  /* Layout */
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* .card-basic > div {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
} */

/* CARD */ 

.card-avances-body {
  height: 792px;
  overflow-y: auto;
  overflow-x: hidden;
}

.card-avances-body::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

.card-avances-body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

.card-dashboard {
  background-color: white;
  border-radius: 20px 20px 20px 20px;
  padding: 20px; 
  margin-bottom: 12px;
}
.card-dashboard > div {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}
.card-dashboard > div:last-child {
  align-items: flex-start;
  flex-direction: column;
}

.card-dashboard h1 {
  color:#30312B;
  font-size: 40px;
  margin-bottom: 0;
  /* line-height: 72px; */
}

.card-dashboard h6 {
  color:#5B5B5B;
  font-size: 14px;
  /* line-height: 28px; */
  letter-spacing: 0.5px;
}

/* CARD - GRAPH */ 

.card-graph h6 {
  color:#5B5B5B;
  font-size: 14px;
  /* line-height: 28px; */
  letter-spacing: 0.5px;
  width: 100%;
}

/* CARD - TABLE */ 
.card-table {
  background-color: #FFFFFF;
  border-radius: 20px 20px 20px 20px;
  padding: 20px;
}

.card-table h6 {
  color:#5B5B5B;
  font-size: 14px;
  /* line-height: 28px; */
  letter-spacing: 0.5px;
  padding: 10px 0;
}

.card-table .table-responsive {
  padding: 0px;
}

.card-table .table {
  border-bottom: 1px solid #e4e7ea;
}

.card-table .table th {
  color: #5F5F59; 
  background-color: rgba(240, 224, 207, 0.4);
  border: 0px solid red;
}
/* CARD - CATEGORY */ 

.card-category-dashboard {
  background-color: white;
  border-radius: 20px 20px 20px 20px;
  padding: 20px; 
  margin-bottom: 20px;
}
.card-category-dashboard > div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center; /* align horizontal */  
}
.card-category-dashboard > div:first-child {
  align-items: center;
}
.card-category-dashboard img {
  margin-bottom: 10px;
}

/* CARD - VERTICAL */ 

.card-vertical-dashboard {
  background-color: white;
  border-radius: 20px 20px 20px 20px;
  padding: 20px; 
  margin-bottom: 20px;
}
.card-vertical-dashboard > div {  
  display: flex;
  align-items: flex-start;
  flex-direction: column;  
}

/* CARDS GENERAL */

@media (max-width: 1280px) and (min-width: 1200px)  {
  .card-avances-body {
    height: 824px;
  }
}

/* INPUTS GROUPS */

.input-group {
  border: 1px solid #d5dbe0;
  border-radius: 4px;
  padding: 1px;
}

.input-group .input-group-text {
  background-color: white;
  border: 0px;
  border-radius: 0px;
}

.input-group .form-control {
  border: 0px;
  padding: 0.5rem 1rem 0.5rem 0rem;
}

.input-group > .btn {
  background-color: #FFFFFF;
}

.input-group .input-group-text > i,
.input-group button > i {
  font-size: 1.5em;
  color: #777771;
}

.input-group .form-control:focus, 
.input-group .btn:focus {  
  /* color: #495057;
  background-color: #fff; */
  border-color: #fff;
  /* outline: 0; */
  box-shadow: 0 0 0 0 #fff;
}

/* LOGIN PAGE */

.news-feed {
  right: 50% !important;
}

.right-content {
  width: 50% !important;
  padding: 120px !important;
}

.login-title-container {
  position: absolute;
  top: 12%;
  width: 400px;
  left: calc(50% - 200px);
}

.login-title-text {
  font-size: 49px;
  line-height: 54px;
  text-align: center;
  letter-spacing: -1.5px;
}

/* GRAFICAS */

/* .nvd3-svg {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
} */

/* NVD3 - PIE CHART */

.nvd3.nv-pie path {
  stroke: transparent;
  stroke-width: 0px;
  stroke-opacity: 0;
}

.nv-pie-title {
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 1.875rem !important;
  fill: #333333 !important;
}

.nv-label text {
  fill: white!important;
}

/* NVD3 - MULTI BAR CHART */
.nv-axis .tick.zero line {
  stroke: transparent;
  stroke-width: 0px;
  stroke-opacity: 0;
}

/* TABLAS */

.text-green {
  color: #789D16 !important;
}


/* BLOCK UI */
.block-ui-overlay {
  /*background-color: red;*/ /*SOLO TEST*/
  border-radius: 20px;
}

.loading-bullet {
  /* color: #FBB03F; */
  color: #789D16;
}

.map-rounded > div > div {
  border-radius: 20px
}

.progress-left-label {
    /* display: inline-block; */
    width: 42px;
    min-width: 42px;
    color: #000;
    font-weight: bold;
}

select option:hover, select option:checked {
  background-color: #CA8225;
  color: white;
  /* box-shadow: 0 0 10px 100px #CA8225 inset; */
}

.btn-sirio:hover {
  /* background-color: #CA8225; */
  box-shadow: 0 3px 2px #00000026;
}

.btn-sirio.btn-outline-secondary {
  color: #374e0010;
  background-color: inherit;
}

.btn-sirio.btn-outline-secondary:hover {
  background-color: #374e0010;
  box-shadow: none;
}

.clickable:hover {
  cursor: pointer;
  text-decoration: underline;
}

.select-ciclo {
    /* width: '180px';
    height: '43px'; */
    /* border-radius: '5px'; */
    font-family: 'Open Sans';
    /* font-style: 'normal'; */
    font-weight: 400;
    font-size: 0.875rem;
    /* padding: '8px'; */
    /* border-color: '#DADADA'; */
}

.select-left-radius > select {
  border-radius: inherit 0 0 inherit;
}

.noborder-right-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.noborder-left-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dot-chart {
  width: 15px;
  height: 15px;
  display: block;
  position: absolute;
  top: 15px;
  border-radius: 4px;
}

.table-sticky {
  height:550px;
  overflow:scroll;
}

.react-datepicker-popper {
  z-index: 1000;
}

.data-value::before input {
  z-index: 1000;
}

.table-sticky table thead tr:nth-last-child(1) th{
  background: white; 
  position: sticky;
  top: 0;
  z-index: 10;
  opacity: 1,
}

tbody > tr:nth-child(2) {
    z-index: 5;
}

h6.heading-box {
  font-size: 1.250rem;
  line-height: 1.438rem;
  color: #253600;
}

.react-datepicker-popper {  z-index: 100 }