input, .react-datepicker-wrapper > div > input {
  /* border-radius: 5px; */
  font-family: "Open Sans"!important;
  font-style: normal!important;
  font-weight: 400!important;
  font-size: 14px!important;
  /* padding: 8px;
  border-color: rgb(218, 218, 218); */
}

span.label-16 {
  font-style: normal!important;
  font-size: 16px!important;
}

input.form-control {
  height: 30px;
}

.invalid-feedback {
  display: block!important;
}

.table-no-border td {
  border: 0!important;
}