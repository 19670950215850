div {
	& + .widget-input {
		border-top: 1px solid $border-color;
		
		&.widget-input-rounded {
			@include border-radius(0 0 $border-radius $border-radius);
		}
	}
}
.widget-input {
	position: relative;
	background: $white;
	
	&.widget-input-rounded {
		@include border-radius($border-radius);
	}
}
.widget-input-container {
	display: table;
	width: 100%;
	
	& .widget-input-icon {
		display: table-cell;
		vertical-align: middle;
		width: 1%;
		padding: 0 rem(5px);
		
		& a {
			display: block;
			padding: rem(10px);
			font-size: rem(18px);
		}
	}
	& .widget-input-box {
		display: table-cell;
		vertical-align: middle;
		padding: rem(5px) 0;
		
		& .form-control {
			border-color: lighten($dark, 75%);
			background: lighten($dark, 75%);
			
			@include border-radius($border-radius * 10);
			
			&:focus {
				@include box-shadow(none);
			}
		}
	}
	& .widget-input-divider {
		display: table-cell;
		vertical-align: middle;
		width: rem(1px);
		
		&:after {
			content: '';
			width: rem(1px);
			height: rem(26px);
			background: lighten($dark, 65%);
			display: block;
		}
	}
}
.inverse-mode {
	&.widget-input,
	& .widget-input {
		background: $dark;
	}
	& .widget-input-box {
		& .form-control {
			background: lighten($dark, 7.5%);
			border-color: lighten($dark, 7.5%);
			color: $white;
			
			@include placeholder(rgba($white, .45));
		}
	}
	& .widget-input-divider {
		&:after {
			background: lighten($dark, 15%);
		}
	}
}
.inverse-mode div + .widget-input,
div + .widget-input.inverse-mode  {
	border-color: $dark;
}