.page-with-light-sidebar {
	& .sidebar {
		background: $white;
		
		& .nav {
			& > li {
				& > a {
					color: lighten($dark, 30%);
					font-weight: 600;
					
					&:hover,
					&:focus {
						color: $dark;
					}
				}
				&.active {
					& > a {
						color: $dark;
						background: $light;
						
						&:hover,
						&:focus {
							color: $dark;
							background: $light;
						}
					}
				}
				&.nav-header {
					color: lighten($dark, 30%);
				}
				&.nav-search {
					& .form-control {
						background: none;
						border: none;
						padding-left: 0;
						padding-right: 0;
						border-bottom: 1px solid $border-color;
						color: $dark;
					
						@include border-radius(0);
					
						&:focus {
							border-color: darken($border-color, 15%);
						
							@include box-shadow(none);
						}
					}
				}
				&.nav-profile {
					
					& > a {
						color: $white !important;
					}
					
					&.active {
						& > a {
							color: $white !important;
						}
					}
				}
			}
			& .sidebar-minify-btn {
				background: $light;
				color: $dark;
			
				& i {
					color: lighten($dark, 45%);
				}
			
				&:hover,
				&:focus {
					background: $light !important;
					color: $dark !important;
					
					& i {
						color: $dark !important;
					}
				}
			}
			&.nav-profile {
				border-color: $light;
			}
		}
		& .sub-menu {
			& > li {
				& > a {
					color: lighten($dark, 30%);
					font-weight: 600 !important;
					
					& .caret {
						color: lighten($dark, 30%);
					}
					&:hover,
					&:focus {
						color: $dark;
					}
					&:after {
						background: darken($light, 7.5%);
					}
				}
				&:before {
					background: darken($light, 7.5%);
				}
				&:after {
					border-width: 2px;
					border-color: darken($light, 7.5%);
					background: $white;
				}
				&.active {
					&:after {
						border-color: $primary;
					}
				}
				&.has-sub {
					& > a {
						&:before {
							background: darken($light, 7.5%);
						}
					}
				}
			}
		}
	}
	& .sidebar-bg {
		background: $white;
	}
	& .slimScrollDiv {
		& .slimScrollBar {
			background: $black !important;
		}
	}
	& .float-sub-menu-container {
		background: $light;
		
		& .float-sub-menu-arrow,
		& .float-sub-menu-line {
			background: darken($light, 7.5%);
		}
		& .float-sub-menu {
			& li {
				& > a {
					color: lighten($dark, 30%);
					font-weight: 600;
					
					&:hover,
					&:focus {
						color: $dark;
					}
					&:after {
						background: darken($light, 7.5%);
					}
				}
				&:before {
					background: darken($light, 7.5%);
				}
				&:after {
					background: $light;
					border-color: darken($light, 7.5%);
					border-width: 2px;
				}
				&.active,
				&.expand,
				&.expanding {
					& > a {
						color: $dark;
					}
				}
			}
		}
	}
	&.page-sidebar-minified {
		& .sidebar {
			& .nav {
				& > li {
					&.active {
						& > a {
							background: $light;
						}
					}
				}
			}
		}
	}
	&.gradient-enabled {
		& .sidebar {
			& .nav {
				& > li {
					&.active {
						& > a {
							color: $white;
							
							@include gradient-linear(to bottom, (lighten($primary, 5%) 0%, darken($primary, 5%) 100%));
						}
					}
				}
			}
		}
	}
}