
@media screen and (max-width: 1920px) {

    .dashboard-titulo-parcelas-has {
        color: #5B5B5B;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 500;
        letter-spacing: 0.15px;
        word-wrap: break-word;
    }

    .dashboard-total-parcelas-has {
        color: #30312B;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
        word-wrap: break-word;
    }


    .dashboard-titulo-grafica {
        color: #5B5B5B;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 500;
        letter-spacing: 0.15px;
        word-wrap: break-word
    }
    
    .dashboard-total-productores {
        color: #30312B;
        font-size: 60px;
        font-family: 'Lato';
        font-weight: 600;
        word-wrap: break-word
    }
    
    .dashboard-total-productores-text {
        color: #5B5B5B;
        font-size: 20px;
        font-family: Lato;
        font-weight: 500;
        letter-spacing: 0.15px;
        word-wrap: break-word;
        text-align: left;
    }

    .dashboard-total-altas-bajas {
        color: #789D16;
        font-size: 48px;
        font-family: 'Lato';
        font-weight: 600;
        text-decoration: underline;
        word-wrap: break-word
    }
    .dashboard-titulo-altas-bajas {
        color: #5B5B5B;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 500;
        letter-spacing: 0.15px;
        word-wrap: break-word
    }
    

}

@media screen and (max-width: 1280px) {

    .dashboard-titulo-parcelas-has {
        color: #5B5B5B;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 500;
        letter-spacing: 0.15px;
        word-wrap: break-word;
    }

    .dashboard-total-parcelas-has {
        color: #30312B;
        font-size: 30px;
        font-family: 'Lato';
        font-weight: 600;
        word-wrap: break-word;
    }

    .dashboard-titulo-grafica {
        color: #5B5B5B;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 500;
        letter-spacing: 0.15px;
        word-wrap: break-word
    }

    .dashboard-total-productores {
        color: #30312B;
        font-size: 50px;
        font-family: 'Lato';
        font-weight: 600;
        word-wrap: break-word
    }
    
    .dashboard-total-productores-text {
        color: #5B5B5B;
        font-size: 18px;
        font-family: Lato;
        font-weight: 500;
        letter-spacing: 0.15px;
        word-wrap: break-word;
        text-align: left;
    }

    .dashboard-total-altas-bajas {
        color: #789D16;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 600;
        text-decoration: underline;
        word-wrap: break-word
    }
    .dashboard-titulo-altas-bajas {
        color: #5B5B5B;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 500;
        letter-spacing: 0.15px;
        word-wrap: break-word
    }
    


}

.dashboard-valores {
	color: #464741;
	font-size: 28px;
	font-family: 'Lato';
	font-weight: 600;
	word-wrap: break-word;
	padding-left: 25px;
}
