.theme-panel {
	position: fixed;
	right: rem(-175px);
	top: rem(150px);
	z-index: $theme-panel-zindex;
	background: $white;
	padding: rem(15px);
	width: rem(175px);
	
	@include transition(right .2s linear);
	@include border-radius($border-radius 0 0 $border-radius);
	@if $enable-rtl {
		right: auto;
		left: rem(-175px);
		
		@include transition(left .2s linear);
	}
	
	& .theme-list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		
		@include display-flex();
		@include flex-wrap(wrap);
		
		& + .theme-list {
			margin-top: rem(10px);
		}
		& > li {
			& + li {
				margin-left: rem(5px);
				
				@if $enable-rtl {
					margin-left: 0;
					margin-right: rem(5px);
				}
			}
			& > a {
				width: rem(20px);
				height: rem(20px);
				display: block;
				text-decoration: none;
				
				@include border-radius($border-radius);
				@include transition(all .2s linear);
				
				&:hover,
				&:focus {
					opacity: .6;
				}
			}
			&.active {
				& > a {
					position: relative;
					
					&:before {
						content: '\f00c';
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						font-size: rem(14px);
						color: $white;
						opacity: .4;
						text-align: center;
						
						@include fontawesome();
					}
					&.bg-white {
						&:before {
							color: $dark;
						}
					}
				}
			}
		}
	}
	& .theme-collapse-btn {
		position: absolute;
		left: rem(-30px);
		top: 10%;
		margin-top: rem(-15px);
		width: rem(30px);
		height: rem(30px);
		line-height: rem(30px);
		font-size: rem(18px);
		color: $dark;
		background: $white;
		text-align: center;
		
		@include border-radius($border-radius 0 0 $border-radius);
		@include box-shadow(0 0 15px 0 rgba($black, .15));
		@if $enable-rtl {
			left: auto;
			right: rem(-30px);
			
			@include border-radius(0 $border-radius $border-radius 0);
		}
		@include media-breakpoint-down(sm) {
			top: 35%;
		}
	}
	& .theme-panel-content {
		margin: rem(-15px);
		padding: rem(15px);
		background: $white;
		position: relative;
		z-index: 10;
		
		@include border-radius($border-radius 0 0 $border-radius);
	}
	& .theme-version {
		margin: 0 0 rem(5px);
		
		@include display-flex();
		@include flex-wrap(wrap);
		
		& a {
			width: calc(50% - 2.5px);
			padding-top: rem(35%);
			position: relative;
			text-decoration: none;
			
			& + a {
				margin-left: rem(5px);
				
				@if $enable-rtl {
					margin-left: 0;
					margin-right: rem(5px);
				}
			}
			& span {
				font-size: rem(14px);
				font-weight: bold;
				margin: 0;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				text-align: center;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: top left;
			}
			
			&.active,
			&:hover {
				&:before {
					content: '';
					position: absolute;
					border: rem(12px) solid transparent;
					border-left-color: lighten($dark, 45%);
					border-top-color: lighten($dark, 45%);
					left: 0;
					top: 0;
					z-index: 10;
				}
				&:after {
					content: '\f00c';
					position: absolute;
					top: 0;
					left: 0;
					color: $white;
					z-index: 10;
					width: rem(16px);
					height: rem(16px);
					line-height: rem(16px);
					font-size: rem(10px);
					text-align: center;
					
					@include fontawesome();
				}
			}
			&.active {
				&:before {
					border-left-color: #007aff;
					border-top-color: #007aff;
				}
			}
		}
	}
	& .control-label {
		@include display-flex();
		@include flex-align(center);
	}
	& .divider {
		height: rem(1px);
		margin: rem(15px) 0;
		background: lighten($dark, 65%);
	}

	&.active {
		right: 0;
		
		@include box-shadow(0 5px 25px rgba($black, .3));
		@if $enable-rtl {
			right: auto;
			left: 0;
		}
		
		& .theme-collapse-btn {
			& .fa {
				&:before {
					content: '\f00d';
				}
			}
		}
		& a {
			&.bg-white {
				border: 1px solid lighten($dark, 60%);
			}
		}
	}
	&.theme-panel-lg {
		top: 0;
		bottom: 0;
		width: rem(260px);
		right: rem(-260px);
		
		@if $enable-rtl {
			right: auto;
			left: rem(-260px);
		}
		
		& .theme-list {
			margin-bottom: rem(-3px);
			
			& > li {
				margin-right: rem(3px);
				margin-bottom: rem(3px);
				
				@if $enable-rtl {
					margin-right: 0;
					margin-left: rem(3px);
				}
				
				& + li {
					margin-left: 0;
					
					@if $enable-rtl {
						margin-right: 0;
					}
				}
				& > a {
					width: rem(28px);
					height: rem(28px);
					
					@include border-radius($border-radius * 10);
					
					&:before {
						line-height: rem(28px);
					}
				}
			}
		}
		& .theme-version {
			margin: 0 0 rem(5px);
		}
		& .theme-panel-content {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: scroll;
			margin: 0;
			padding: rem(20px);
			
			@include border-radius(0);
		}
		& .theme-collapse-btn {
			top: 30%;
			
			@include media-breakpoint-down(sm) {
				top: 35%;
			}
		}
		&.active {
			right: 0;
			
			@if $enable-rtl {
				left: 0;
				right: auto;
			}
		}
	}
}