.icon-eye-open {
	@include fontawesome();
	
	&:before {
		content: '\f06e';
	}
}
.icon-eye-close {
	@include fontawesome();
	
	&:before {
		content: '\f070';
	}
}
