.div-red {
    background-color: red;
  }
  
  .btn-entregas-acopios{
    padding-top: 10px;
    margin-top: 20px;
    padding-left: 25px;
    font-size: 18px;
  }
  
  .titulo-input {
    font-size: 18px;
    font-weight:500;
    margin-bottom: 10px;
  }
  
  .info-input{
    font-size: 14px;
  }

  .titulo-pregunta {
    color: var(--Neutral-700, #464741);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
  }