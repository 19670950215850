.lotes-kgs-total-label{
    color: var(--Neutral-700, #464741);
    font-family: Lato;
    font-size: 15.235px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.314px; /* 133.333% */
    letter-spacing: 0.508px;
}

.lotes-kgs-total-valor{
    color: var(--Neutral-700, #464741);
    /* H5/Lato Regular */
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.lotes-kgs-entregados-label{
    color: var(--Neutral-700, #464741);
    font-family: Lato;
    font-size: 15.235px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.314px;
    letter-spacing: 0.508px;
}

.lotes-kgs-entregados-valor{
    color: var(--Neutral-700, #464741);
    /* H5/Lato Regular */
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.lotes-div-grafica{
    width: '100%';
    height: '250px';
    margin-left: '25%';
}

.entregas-titulo{
    color: #000;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

.entregas-subtitulo{
    color: var(--Neutral-500, #777771);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}

.div-nombre-lote{
    height: 54px;
    background-color: #789D16;
    color: var(--White-High-Emphasis, #FFF);
    /* Body 1/Lato Regular */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    letter-spacing: 0.5px;
    padding: 10px;

}

.div-lote-seleccionado{
    display: inline-flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--Neutral-50, #F2F1E8);
}

.texto-lote-seleccionado{
    color: var(--Neutral-800, #30312B);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.42px;
    padding: 10px;
}

.x-lote-seleccionado{
    color: var(--Neutral-800, #757571);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding: 5px;
}


.acopio-lote-total{
    color: var(--Neutral-700, #464741);
    /* H5/Lato Regular */
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.acopio-lote-desc{
    color: var(--Neutral-700, #464741);
    font-family: Lato;
    font-size: 15.235px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.314px;
    letter-spacing: 0.508px;
}

.acopio-lote-asignacion-div{
    display: flex;
    width: 245px;
    height: 50px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
}

.acopio-lote-asignacion-desc{
    color: #000;
    /* Body 2/Lato Regular */
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px; 
}