.tab-overflow {
	position: relative;
	overflow: hidden;
	
	@include border-radius($border-radius-sm $border-radius-sm 0 0);
	@include flex(1);
	
	& .nav {
		&.nav-tabs {
			white-space: nowrap;
			display: table;
			margin: 0;
			
			@include border-radius($border-radius $border-radius 0 0);
			
			& > li {
				display: table-cell;
				float: none;
				
				& > a {
					margin-left: rem(-1px);
					
					@if $enable-rtl {
						margin-left: 0;
						margin-right: rem(-1px);
					}
				}
			}
			& .prev-button {
				position: absolute;
				left: 0;
				top: 0;
				z-index: 10;
				display: none !important;
				opacity: 0;
		
				@include box-shadow(0 0 15px rgba($dark, .25));
				@include transition(all .2s linear);
				@if $enable-rtl {
					left: auto;
					right: 0;
				}
				
				& a {
					margin: 0;
					background: $light;
					
					@include border-radius($border-radius 0 0);
				}
			}
			& .next-button {
				position: absolute;
				right: 0;
				top: 0;
				z-index: 10;
				display: none !important;
				opacity: 0;
		
				@include box-shadow(0 0 15px rgba($dark, .25));
				@include transition(all .2s linear);
				@include border-top-left-radius(0);
				@if $enable-rtl {
					right: auto;
					left: 0;
				}
				
				& a {
					margin: 0;
					background: $light;
					
					@include border-radius(0 $border-radius 0 0);
				}
			}
			
			&.nav-tabs-inverse {
				& > li {
					& > a {
						background: none;
						
						&.active {
							background: $white;
							color: $dark;
						}
					}
				}
				& .next-button, 
				& .prev-button {
					@include box-shadow(0 0 15px $black);
					
					& + li {
						& > a {
							&.active {
								@include border-radius(0 $border-radius 0 0);
							}
						}
					}
					& > a {
						background: $dark-darker;
					}
				}
			}
		}
	}
	
	&.overflow-right {
		& .nav {
			&.nav-tabs{
				& .next-button {
					display: block !important;
					opacity: 1;
					background: none;
				}
			}
		}
	}
	&.overflow-left {
		& .nav {
			&.nav-tabs{
				& .prev-button {
					display: block !important;
					opacity: 1;
					background: none;
				}
			}
		}
	}
}
.flat-black .tab-overflow .next-button a,
.flat-black .tab-overflow .prev-button a {
	background: #d2d2d2;
}