/* RESET STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.chart-skills {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.chart-skills *,
.chart-skills::before {
  box-sizing: border-box;
}


/* CHART-SKILLS STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.chart-skills {
  position: relative;
  overflow: hidden;
}

.chart-skills::before,
.chart-skills::after {
  position: absolute;
}

.chart-skills::before {
  content: '';
  width: inherit;
  height: inherit;
  border: 65px solid rgba(211, 211, 211, .3);
  border-bottom: none;
  /* border-top-left-radius: calc(attr(height));
  border-top-right-radius: calc(attr(height)); */
}

.chart-skills-350::before {
  border-width: 65px;
  border-top-left-radius: 175px;
  border-top-right-radius: 175px;
}

.chart-skills-250::before {
  border-width: 40px;
  border-top-left-radius: 125px;
  border-top-right-radius: 125px;
} 

/* .chart-skills::after {
  content: '64%';
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  font-size: 1.1rem;
  font-weight: bold;
  color: cadetblue;
} */

.chart-skills li {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: inherit;
  border: 65px solid;
  border-top: none;
  /* border-bottom-left-radius: 175px;
  border-bottom-right-radius: 175px; */
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
  animation-duration: .4s;
  animation-timing-function: linear;
}

.chart-skills-350 li {
  border-width: 65px;
}

.chart-skills-250 li {
  border-width: 40px;
} 

.chart-skills li:nth-child(1) {
  z-index: 4;
  border-color: red;
  /* animation-name: rotate-one; */
}

/* ANIMATIONS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* @keyframes rotate-one {
  100% {
    transform: rotate(90deg);
    
  }
} */

@keyframes fade-in {
  0%,
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}