.reporte-evaluacion {
  font-family: "Open Sans"!important;
  font-style: normal!important;
  font-weight: 400!important;
  font-size: 14px!important;
  /* font-size: 16px!important; */
}
.text-middle {
  vertical-align: middle!important;
}

.w-20 {
  width: 20% !important;
}
